export const SWITCH_MOBILE = "SWITCH_MOBILE";
export const LOAD_TEAM_DATA = "LOAD_TEAM_DATA";
export const LOAD_PLAYER_DATA = "LOAD_PLAYER_DATA";
export const LOAD_GAMEWEEK_DATA = "LOAD_GAMEWEEK_DATA";
export const LOAD_FIXTURES_DATA = "LOAD_FIXTURES_DATA";
export const LOAD_DRAFTS = "LOAD_DRAFTS";
export const LOAD_DRAFTS_BY_ID = "LOAD_DRAFTS_BY_ID";
export const LOAD_COLLECTIONS = "LOAD_COLLECTIONS";
export const LOAD_SINGLE_DRAFT = "LOAD_SINGLE_DRAFT";
export const SET_CURRENT_GAMEWEEK = "SET_CURRENT_GAMEWEEK";
export const SELECT_DRAFT = "SELECT_DRAFT";
export const SELECT_GAMEWEEK = "SELECT_GAMEWEEK";
export const SELECT_COLLECTION = "SELECT_COLLECTION";
export const SELECT_COLLECTION_DRAFT = "SELECT_COLLECTION_DRAFT";
export const DELETE_DRAFT = "DELETE_DRAFT";
export const DELETE_COLLECTION_DRAFT = "DELETE_COLLECTION_DRAFT";
export const RENAME_DRAFT = "RENAME_DRAFT";
export const MOVE_DRAFT = "MOVE_DRAFT";
export const START_LOADING_STATIC_DATA = "START_LOADING_STATIC_DATA";
export const STOP_LOADING_STATIC_DATA = "STOP_LOADING_STATIC_DATA";
export const OPEN_RENAME_MODAL = "OPEN_RENAME_MODAL";
export const START_RENAME_REQUEST = "START_RENAME_REQUEST";
export const STOP_RENAME_REQUEST = "STOP_RENAME_REQUEST";
export const SET_RENAME_REQUEST_ERROR = "SET_RENAME_REQUEST_ERROR";
export const CLOSE_RENAME_MODAL = "CLOSE_RENAME_MODAL";
export const OPEN_MOVE_MODAL = "OPEN_MOVE_MODAL";
export const START_MOVE_REQUEST = "START_MOVE_REQUEST";
export const STOP_MOVE_REQUEST = "STOP_MOVE_REQUEST";
export const CLOSE_MOVE_MODAL = "CLOSE_MOVE_MODAL";
export const OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL";
export const START_DELETE_REQUEST = "START_DELETE_REQUEST";
export const STOP_DELETE_REQUEST = "STOP_DELETE_REQUEST";
export const CLOSE_DELETE_MODAL = "CLOSE_DELETE_MODAL";
export const OPEN_SHARE_MODAL = "OPEN_SHARE_MODAL";
export const CLOSE_SHARE_MODAL = "CLOSE_SHARE_MODAL";
